import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer
    style={{
      height: "5vh",
    }}
    ></footer>
  )
}
