import React from "react"
import { Link } from "gatsby"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link
      to={props.to}
      style={{
        color: "lightgray",
      }}
    >
      {props.children}
    </Link>
  </li>
)

export default function Header() {
  return (
    <ul
      style={{
        height: "5vh",
        listStyle: `none`,
        float: `top`,
        color: "darkgray",
      }}
    >
      <ListLink to="/">Dagens vers</ListLink>
      <ListLink to="/allaverser/">Alla verser</ListLink>
    </ul>
  )
}
